/* Menu.module.css */
.fadeIn {
  animation: fadeInAnimation 0.25s forwards; /* Use 'forwards' to keep the final state */
}

.fadeOut {
  animation: fadeOutAnimation 0.25s forwards;
}

@keyframes fadeInAnimation {
  from {
    opacity: 0;
    visibility: hidden;
  }
  to {
    opacity: 1;
    visibility: visible;
  }
}

@keyframes fadeOutAnimation {
  from {
    opacity: 1;
    visibility: visible;
  }
  to {
    opacity: 0;
    visibility: hidden;
  }
}
